// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import type { FormatXMLElementFn } from '@vocab/core';
import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    'First Name': () =>
      | 'First name'
      | 'ชื่อ'
      | 'Name Depan'
      | '[Ƒìììřšṯ กี้ăăăm̂ẽẽẽ]';
    'Last Name': () =>
      | 'Last name'
      | 'นามสกุล'
      | 'Nama Belakang'
      | '[Łăăăšṯ กี้ăăăm̂ẽẽẽ]';
    'Email Address': () =>
      | 'Email address'
      | 'ที่อยู่อีเมล'
      | 'Alamat Email'
      | '[Ëm̂ăăăìììƚ ăăăƌƌřẽẽẽšš]';
    'Primary Industry': () =>
      | 'Your primary industry'
      | 'ประเภทธุรกิจขององค์กรคุณ'
      | 'industri utama Anda'
      | '[Ýöööǚǚǚř ƥřìììm̂ăăăřýýý ìììกี้ƌǚǚǚšṯřýýý]';
    'Select an Industry': () =>
      | 'Select an industry'
      | 'เลือกประเภทธุรกิจของคุณ'
      | 'pilih jenis industri'
      | '[Šẽẽẽƚẽẽẽçṯ ăăăกี้ ìììกี้ƌǚǚǚšṯřýýý]';
    'Unsubscribe Message': <T = string>(values: {
      Link: FormatXMLElementFn<T>;
      privacyAgreement: string;
    }) => ReturnType<FormatXMLElementFn<T>>;
    'Subscribe to Hiring Advice': () =>
      | 'Subscribe to Hiring Advice'
      | 'สมัครรับคำแนะนำเกี่ยวกับการจ้างงาน'
      | 'Langganan Tips Rekrutmen'
      | '[Šǚǚǚßšçřìììßẽẽẽ ṯööö Ḩìììřìììกี้ģ Ậƌṽìììçẽẽẽ]';
    'Subscribe to Market Insights': () =>
      | 'Subscribe to Market Insights'
      | 'สมัครรับข้อมูลเชิงลึกของตลาด'
      | 'Berlangganan Wawasan Pasar'
      | '[Šǚǚǚßšçřìììßẽẽẽ ṯööö Ṃăăăřķẽẽẽṯ Ïกี้šìììģḩṯš]';
    'Get expert hiring advice delivered to your inbox.': () =>
      | 'Get expert Hiring Advice delivered to your inbox.'
      | 'รับข่าวสารเกี่ยวกับ คำแนะนำการจ้างงาน ผ่านทางอีเมลของคุณ'
      | 'Dapatkan pesan dari para pakar Tips Rekrutmen langsung ke kotak masuk Anda.'
      | '[Ǧẽẽẽṯ ẽẽẽꭕƥẽẽẽřṯ Ḩìììřìììกี้ģ Ậƌṽìììçẽẽẽ ƌẽẽẽƚìììṽẽẽẽřẽẽẽƌ ṯööö ýýýöööǚǚǚř ìììกี้ßöööꭕ.]';
    'Get expert market insights delivered to your inbox.': () =>
      | 'Get expert Market Insights delivered to your inbox.'
      | 'รับข่าวสารเกี่ยวกับ ภาพรวมตลาดงาน ผ่านทางอีเมลของคุณ'
      | 'Dapatkan pesan dari para pakar Wawasan Pasar langsung ke kotak masuk Anda.'
      | '[Ǧẽẽẽṯ ẽẽẽꭕƥẽẽẽřṯ Ṃăăăřķẽẽẽṯ Ïกี้šìììģḩṯš ƌẽẽẽƚìììṽẽẽẽřẽẽẽƌ ṯööö ýýýöööǚǚǚř ìììกี้ßöööꭕ.]';
    Subscribe: () =>
      | 'Subscribe'
      | 'สมัคร'
      | 'Berlangganan'
      | '[Šǚǚǚßšçřìììßẽẽẽ]';
    'Please enter': () =>
      | 'Please enter'
      | 'กรุณาป้อน'
      | 'Silakan masuk'
      | '[Ƥƚẽẽăăšẽẽ ẽẽกี้ṯẽẽř]';
    'Thanks for subscribing': () =>
      | 'Thanks for subscribing!'
      | 'ขอบคุณสำหรับการสมัคร'
      | 'Terima kasih telah berlangganan!'
      | '[Ṯḩăăăกี้ķš ƒöööř šǚǚǚßšçřìììßìììกี้ģ!]';
    'Corporate Business': () =>
      | 'Corporate Business'
      | 'ธุรกิจองค์กร'
      | 'Bisnis perusahaan'
      | '[Çöööřƥöööřăăăṯẽẽẽ ẞǚǚǚšìììกี้ẽẽẽšš]';
    'Public sector/government': () =>
      | 'Public sector/government'
      | 'ภาครัฐ/รัฐบาล'
      | 'Sektor publik/Pemerintah'
      | '[Ƥǚǚǚßƚìììç šẽẽẽçṯöööř/ģöööṽẽẽẽřกี้m̂ẽẽẽกี้ṯ]';
    'Small to medium business': () =>
      | 'Small to medium business'
      | 'ธุรกิจขนาดเล็กถึงขนาดกลาง'
      | 'Usaha kecil hingga menengah'
      | '[Šm̂ăăăƚƚ ṯööö m̂ẽẽẽƌìììǚǚǚm̂ ßǚǚǚšìììกี้ẽẽẽšš]';
    Recruitment: () =>
      | 'Recruitment'
      | ' รับสมัคร'
      | 'Pengerahan'
      | '[Řẽẽẽçřǚǚǚìììṯm̂ẽẽẽกี้ṯ]';
    'Something Wrong': () =>
      | 'Something went wrong, please try again.'
      | 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง.'
      | 'Terjadi kesalahan, harap coba lagi.'
      | '[Šöööm̂ẽẽẽṯḩìììกี้ģ ŵẽẽẽกี้ṯ ŵřöööกี้ģ, ƥƚẽẽẽăăăšẽẽẽ ṯřýýý ăăăģăăăìììกี้.]';
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.': <
      T = string,
    >(values: {
      collectionTextLink: FormatXMLElementFn<T>;
      privacyTextLink: FormatXMLElementFn<T>;
    }) => ReturnType<FormatXMLElementFn<T>>;
  }
>({
  en: createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  th: createLanguage({
    'First Name': 'ชื่อ',
    'Last Name': 'นามสกุล',
    'Email Address': 'ที่อยู่อีเมล',
    'Primary Industry': 'ประเภทธุรกิจขององค์กรคุณ',
    'Select an Industry': 'เลือกประเภทธุรกิจของคุณ',
    'Unsubscribe Message':
      "คุณสามารถยกเลิกการติดตามข่าวสารผ่านช่องทางอีเมลได้ตลอดเวลา โปรดรู้ไว้ว่าเมื่อกด 'ติดตามข่าวสาร' คุณได้ยอมรับเงื่อนไข <Link> {privacyAgreement} </Link>",
    'Subscribe to Hiring Advice': 'สมัครรับคำแนะนำเกี่ยวกับการจ้างงาน',
    'Subscribe to Market Insights': 'สมัครรับข้อมูลเชิงลึกของตลาด',
    'Get expert hiring advice delivered to your inbox.':
      'รับข่าวสารเกี่ยวกับ คำแนะนำการจ้างงาน ผ่านทางอีเมลของคุณ',
    'Get expert market insights delivered to your inbox.':
      'รับข่าวสารเกี่ยวกับ ภาพรวมตลาดงาน ผ่านทางอีเมลของคุณ',
    Subscribe: 'สมัคร',
    'Please enter': 'กรุณาป้อน',
    'Thanks for subscribing': 'ขอบคุณสำหรับการสมัคร',
    'Corporate Business': 'ธุรกิจองค์กร',
    'Public sector/government': 'ภาครัฐ/รัฐบาล',
    'Small to medium business': 'ธุรกิจขนาดเล็กถึงขนาดกลาง',
    Recruitment: ' รับสมัคร',
    'Something Wrong': 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'ท่านได้ยอมรับ<collectionTextLink>คำประกาศเกี่ยวกับการเก็บรวบรวมข้อมูลส่วนบุคคล</collectionTextLink> และ<privacyTextLink>นโยบายความเป็นส่วนตัว</privacyTextLink>เพื่อให้ข้อมูลส่วนบุคคลของท่าน ท่านสามารถยกเลิกได้ทุกเวลา',
  }),
  id: createLanguage({
    'First Name': 'Name Depan',
    'Last Name': 'Nama Belakang',
    'Email Address': 'Alamat Email',
    'Primary Industry': 'industri utama Anda',
    'Select an Industry': 'pilih jenis industri',
    'Unsubscribe Message':
      "Anda dapat berhenti berlangganan email kapan saja. Dengan mengklik 'berlangganan' Anda setuju dengan <Link> {privacyAgreement} </Link> kami",
    'Subscribe to Hiring Advice': 'Langganan Tips Rekrutmen',
    'Subscribe to Market Insights': 'Berlangganan Wawasan Pasar',
    'Get expert hiring advice delivered to your inbox.':
      'Dapatkan pesan dari para pakar Tips Rekrutmen langsung ke kotak masuk Anda.',
    'Get expert market insights delivered to your inbox.':
      'Dapatkan pesan dari para pakar Wawasan Pasar langsung ke kotak masuk Anda.',
    Subscribe: 'Berlangganan',
    'Please enter': 'Silakan masuk',
    'Thanks for subscribing': 'Terima kasih telah berlangganan!',
    'Corporate Business': 'Bisnis perusahaan',
    'Public sector/government': 'Sektor publik/Pemerintah',
    'Small to medium business': 'Usaha kecil hingga menengah',
    Recruitment: 'Pengerahan',
    'Something Wrong': 'Terjadi kesalahan, harap coba lagi.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'Dengan memberikan informasi pribadi Anda, Anda menyetujui <collectionTextLink>Pemberitahuan Pengumpulan</collectionTextLink> dan <privacyTextLink>Kebijakan Privasi</privacyTextLink>. Anda dapat berhenti berlangganan kapan saja.',
  }),
  zh: createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  'en-AU': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  'en-HK': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  'en-ID': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  'en-MY': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  'en-NZ': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  'en-PH': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  'en-SG': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  'en-TH': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  'th-TH': createLanguage({
    'First Name': 'ชื่อ',
    'Last Name': 'นามสกุล',
    'Email Address': 'ที่อยู่อีเมล',
    'Primary Industry': 'ประเภทธุรกิจขององค์กรคุณ',
    'Select an Industry': 'เลือกประเภทธุรกิจของคุณ',
    'Unsubscribe Message':
      "คุณสามารถยกเลิกการติดตามข่าวสารผ่านช่องทางอีเมลได้ตลอดเวลา โปรดรู้ไว้ว่าเมื่อกด 'ติดตามข่าวสาร' คุณได้ยอมรับเงื่อนไข <Link> {privacyAgreement} </Link>",
    'Subscribe to Hiring Advice': 'สมัครรับคำแนะนำเกี่ยวกับการจ้างงาน',
    'Subscribe to Market Insights': 'สมัครรับข้อมูลเชิงลึกของตลาด',
    'Get expert hiring advice delivered to your inbox.':
      'รับข่าวสารเกี่ยวกับ คำแนะนำการจ้างงาน ผ่านทางอีเมลของคุณ',
    'Get expert market insights delivered to your inbox.':
      'รับข่าวสารเกี่ยวกับ ภาพรวมตลาดงาน ผ่านทางอีเมลของคุณ',
    Subscribe: 'สมัคร',
    'Please enter': 'กรุณาป้อน',
    'Thanks for subscribing': 'ขอบคุณสำหรับการสมัคร',
    'Corporate Business': 'ธุรกิจองค์กร',
    'Public sector/government': 'ภาครัฐ/รัฐบาล',
    'Small to medium business': 'ธุรกิจขนาดเล็กถึงขนาดกลาง',
    Recruitment: ' รับสมัคร',
    'Something Wrong': 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'ท่านได้ยอมรับ<collectionTextLink>คำประกาศเกี่ยวกับการเก็บรวบรวมข้อมูลส่วนบุคคล</collectionTextLink> และ<privacyTextLink>นโยบายความเป็นส่วนตัว</privacyTextLink>เพื่อให้ข้อมูลส่วนบุคคลของท่าน ท่านสามารถยกเลิกได้ทุกเวลา',
  }),
  'id-ID': createLanguage({
    'First Name': 'Name Depan',
    'Last Name': 'Nama Belakang',
    'Email Address': 'Alamat Email',
    'Primary Industry': 'industri utama Anda',
    'Select an Industry': 'pilih jenis industri',
    'Unsubscribe Message':
      "Anda dapat berhenti berlangganan email kapan saja. Dengan mengklik 'berlangganan' Anda setuju dengan <Link> {privacyAgreement} </Link> kami",
    'Subscribe to Hiring Advice': 'Langganan Tips Rekrutmen',
    'Subscribe to Market Insights': 'Berlangganan Wawasan Pasar',
    'Get expert hiring advice delivered to your inbox.':
      'Dapatkan pesan dari para pakar Tips Rekrutmen langsung ke kotak masuk Anda.',
    'Get expert market insights delivered to your inbox.':
      'Dapatkan pesan dari para pakar Wawasan Pasar langsung ke kotak masuk Anda.',
    Subscribe: 'Berlangganan',
    'Please enter': 'Silakan masuk',
    'Thanks for subscribing': 'Terima kasih telah berlangganan!',
    'Corporate Business': 'Bisnis perusahaan',
    'Public sector/government': 'Sektor publik/Pemerintah',
    'Small to medium business': 'Usaha kecil hingga menengah',
    Recruitment: 'Pengerahan',
    'Something Wrong': 'Terjadi kesalahan, harap coba lagi.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'Dengan memberikan informasi pribadi Anda, Anda menyetujui <collectionTextLink>Pemberitahuan Pengumpulan</collectionTextLink> dan <privacyTextLink>Kebijakan Privasi</privacyTextLink>. Anda dapat berhenti berlangganan kapan saja.',
  }),
  'zh-HK': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Email Address': 'Email address',
    'Primary Industry': 'Your primary industry',
    'Select an Industry': 'Select an industry',
    'Unsubscribe Message':
      'You can unsubscribe from emails at any time. By clicking ‘subscribe’ you agree to our <Link>{privacyAgreement}</Link>',
    'Subscribe to Hiring Advice': 'Subscribe to Hiring Advice',
    'Subscribe to Market Insights': 'Subscribe to Market Insights',
    'Get expert hiring advice delivered to your inbox.':
      'Get expert Hiring Advice delivered to your inbox.',
    'Get expert market insights delivered to your inbox.':
      'Get expert Market Insights delivered to your inbox.',
    Subscribe: 'Subscribe',
    'Please enter': 'Please enter',
    'Thanks for subscribing': 'Thanks for subscribing!',
    'Corporate Business': 'Corporate Business',
    'Public sector/government': 'Public sector/government',
    'Small to medium business': 'Small to medium business',
    Recruitment: 'Recruitment',
    'Something Wrong': 'Something went wrong, please try again.',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      'By providing your personal information, you agree to the <collectionTextLink>Collection Notice</collectionTextLink> and <privacyTextLink>Privacy Policy</privacyTextLink>. You can unsubscribe at any time.',
  }),
  'en-PSEUDO': createLanguage({
    'First Name': '[Ƒìììřšṯ กี้ăăăm̂ẽẽẽ]',
    'Last Name': '[Łăăăšṯ กี้ăăăm̂ẽẽẽ]',
    'Email Address': '[Ëm̂ăăăìììƚ ăăăƌƌřẽẽẽšš]',
    'Primary Industry': '[Ýöööǚǚǚř ƥřìììm̂ăăăřýýý ìììกี้ƌǚǚǚšṯřýýý]',
    'Select an Industry': '[Šẽẽẽƚẽẽẽçṯ ăăăกี้ ìììกี้ƌǚǚǚšṯřýýý]',
    'Unsubscribe Message':
      '[Ýöööǚǚǚ çăăăกี้ ǚǚǚกี้šǚǚǚßšçřìììßẽẽẽ ƒřöööm̂ ẽẽẽm̂ăăăìììƚš ăăăṯ ăăăกี้ýýý ṯìììm̂ẽẽẽ. ẞýýý çƚìììçķìììกี้ģ ‘šǚǚǚßšçřìììßẽẽẽ’ ýýýöööǚǚǚ ăăăģřẽẽẽẽẽẽ ṯööö öööǚǚǚř <Link>{privacyAgreement}</Link>]',
    'Subscribe to Hiring Advice':
      '[Šǚǚǚßšçřìììßẽẽẽ ṯööö Ḩìììřìììกี้ģ Ậƌṽìììçẽẽẽ]',
    'Subscribe to Market Insights':
      '[Šǚǚǚßšçřìììßẽẽẽ ṯööö Ṃăăăřķẽẽẽṯ Ïกี้šìììģḩṯš]',
    'Get expert hiring advice delivered to your inbox.':
      '[Ǧẽẽẽṯ ẽẽẽꭕƥẽẽẽřṯ Ḩìììřìììกี้ģ Ậƌṽìììçẽẽẽ ƌẽẽẽƚìììṽẽẽẽřẽẽẽƌ ṯööö ýýýöööǚǚǚř ìììกี้ßöööꭕ.]',
    'Get expert market insights delivered to your inbox.':
      '[Ǧẽẽẽṯ ẽẽẽꭕƥẽẽẽřṯ Ṃăăăřķẽẽẽṯ Ïกี้šìììģḩṯš ƌẽẽẽƚìììṽẽẽẽřẽẽẽƌ ṯööö ýýýöööǚǚǚř ìììกี้ßöööꭕ.]',
    Subscribe: '[Šǚǚǚßšçřìììßẽẽẽ]',
    'Please enter': '[Ƥƚẽẽăăšẽẽ ẽẽกี้ṯẽẽř]',
    'Thanks for subscribing': '[Ṯḩăăăกี้ķš ƒöööř šǚǚǚßšçřìììßìììกี้ģ!]',
    'Corporate Business': '[Çöööřƥöööřăăăṯẽẽẽ ẞǚǚǚšìììกี้ẽẽẽšš]',
    'Public sector/government': '[Ƥǚǚǚßƚìììç šẽẽẽçṯöööř/ģöööṽẽẽẽřกี้m̂ẽẽẽกี้ṯ]',
    'Small to medium business': '[Šm̂ăăăƚƚ ṯööö m̂ẽẽẽƌìììǚǚǚm̂ ßǚǚǚšìììกี้ẽẽẽšš]',
    Recruitment: '[Řẽẽẽçřǚǚǚìììṯm̂ẽẽẽกี้ṯ]',
    'Something Wrong':
      '[Šöööm̂ẽẽẽṯḩìììกี้ģ ŵẽẽẽกี้ṯ ŵřöööกี้ģ, ƥƚẽẽẽăăăšẽẽẽ ṯřýýý ăăăģăăăìììกี้.]',
    'By providing your personal information, you agree to the Collection Notice and Privacy policy. You can unsubscribe at any time.':
      '[ẞýý ƥřööṽììƌììกี้ģ ýýööǚǚř ƥẽẽřšööกี้ăăƚ ììกี้ƒööřm̂ăăṯììööกี้, ýýööǚǚ ăăģřẽẽẽẽ ṯöö ṯḩẽẽ <collectionTextLink>Çööƚƚẽẽçṯììööกี้ Ñööṯììçẽẽ</collectionTextLink> ăăăกี้ƌ <privacyTextLink>Ƥřììṽăăçýý Ƥööƚììçýý</privacyTextLink>. Ýöööǚǚǚ çăăăกี้ ǚǚǚกี้šǚǚǚßšçřìììßẽẽẽ ăăăṯ ăăăกี้ýýý ṯìììm̂ẽẽẽ.]',
  }),
});

export default translations;
